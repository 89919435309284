export const partnersData = [
  {
    title: 'aws',
    fluid: {
      aspectRatio: 1.6666666666666667,
      src:
        '//images.ctfassets.net/1n69jtns7cd1/7tmRvhYarYTbEDUe0aSrZu/cdb30713a52be8692b71b63e31581aae/aws.png?w=1000&q=50',
      srcSet:
        '//images.ctfassets.net/1n69jtns7cd1/7tmRvhYarYTbEDUe0aSrZu/cdb30713a52be8692b71b63e31581aae/aws.png?w=250&h=150&q=50 250w,\n//images.ctfassets.net/1n69jtns7cd1/7tmRvhYarYTbEDUe0aSrZu/cdb30713a52be8692b71b63e31581aae/aws.png?w=400&h=240&q=50 400w',
      srcWebp:
        '//images.ctfassets.net/1n69jtns7cd1/7tmRvhYarYTbEDUe0aSrZu/cdb30713a52be8692b71b63e31581aae/aws.png?w=1000&q=50&fm=webp',
      srcSetWebp:
        '//images.ctfassets.net/1n69jtns7cd1/7tmRvhYarYTbEDUe0aSrZu/cdb30713a52be8692b71b63e31581aae/aws.png?w=250&h=150&q=50&fm=webp 250w,\n//images.ctfassets.net/1n69jtns7cd1/7tmRvhYarYTbEDUe0aSrZu/cdb30713a52be8692b71b63e31581aae/aws.png?w=400&h=240&q=50&fm=webp 400w',
      sizes: '(max-width: 1000px) 100vw, 1000px',
    },
  },
  {
    title: 'Snowflake',
    fluid: {
      aspectRatio: 1.7718446601941749,
      src:
        '//images.ctfassets.net/1n69jtns7cd1/6uekyC8goqE9CyIEUB7D2U/92459ee7d982f2452a624529b9b4f277/snowflake.png?w=1000&q=50',
      srcSet:
        '//images.ctfassets.net/1n69jtns7cd1/6uekyC8goqE9CyIEUB7D2U/92459ee7d982f2452a624529b9b4f277/snowflake.png?w=250&h=141&q=50 250w,\n//images.ctfassets.net/1n69jtns7cd1/6uekyC8goqE9CyIEUB7D2U/92459ee7d982f2452a624529b9b4f277/snowflake.png?w=365&h=206&q=50 365w',
      srcWebp:
        '//images.ctfassets.net/1n69jtns7cd1/6uekyC8goqE9CyIEUB7D2U/92459ee7d982f2452a624529b9b4f277/snowflake.png?w=1000&q=50&fm=webp',
      srcSetWebp:
        '//images.ctfassets.net/1n69jtns7cd1/6uekyC8goqE9CyIEUB7D2U/92459ee7d982f2452a624529b9b4f277/snowflake.png?w=250&h=141&q=50&fm=webp 250w,\n//images.ctfassets.net/1n69jtns7cd1/6uekyC8goqE9CyIEUB7D2U/92459ee7d982f2452a624529b9b4f277/snowflake.png?w=365&h=206&q=50&fm=webp 365w',
      sizes: '(max-width: 1000px) 100vw, 1000px',
    },
  },
  {
    title: 'Google Cloud Platform',
    fluid: {
      aspectRatio: 1.6666666666666667,
      src:
        '//images.ctfassets.net/1n69jtns7cd1/4r4VpWNAkQIdnKiPgKXavE/45c23b1340ca308374f426523ad95fff/gcp.png?w=1000&q=50',
      srcSet:
        '//images.ctfassets.net/1n69jtns7cd1/4r4VpWNAkQIdnKiPgKXavE/45c23b1340ca308374f426523ad95fff/gcp.png?w=250&h=150&q=50 250w,\n//images.ctfassets.net/1n69jtns7cd1/4r4VpWNAkQIdnKiPgKXavE/45c23b1340ca308374f426523ad95fff/gcp.png?w=400&h=240&q=50 400w',
      srcWebp:
        '//images.ctfassets.net/1n69jtns7cd1/4r4VpWNAkQIdnKiPgKXavE/45c23b1340ca308374f426523ad95fff/gcp.png?w=1000&q=50&fm=webp',
      srcSetWebp:
        '//images.ctfassets.net/1n69jtns7cd1/4r4VpWNAkQIdnKiPgKXavE/45c23b1340ca308374f426523ad95fff/gcp.png?w=250&h=150&q=50&fm=webp 250w,\n//images.ctfassets.net/1n69jtns7cd1/4r4VpWNAkQIdnKiPgKXavE/45c23b1340ca308374f426523ad95fff/gcp.png?w=400&h=240&q=50&fm=webp 400w',
      sizes: '(max-width: 1000px) 100vw, 1000px',
    },
  },
];

export default partnersData;
